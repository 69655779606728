<template>
    <div class="collection-view">
        <ControlBar @create-click="onCreate"></ControlBar>
        <div class="content">
            <n-data-table
                class="table"
                :columns="tableColumns"
                :data="tableData"
                :loading="tableLoading"
            ></n-data-table>
            <div class="pagination">
                <n-pagination
                    v-model:page="pageIndex"
                    v-model:page-size="pageSize"
                    :page-count="pageCount"
                    show-size-picker
                    :page-sizes="[20, 50, 100]"
                    @update:page="onPageIndexChange"
                    @update:page-size="onPageSizeChange"
                ></n-pagination>
            </div>
        </div>
        <CollectionEditor
            v-model:show="isEditorShow"
            v-model:collection="selectedCollection"
            @complete="loadData"
        />
        <CollectionDataView
            v-model:show="isViewCollectionShow"
            :collection="viewCollection"
            :show-edit="true"
            @edit-click="editCollectionFromCollectionView"
        />
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';
.collection-view {
    .common-content;

    .content {
        margin-top: 10px;
        padding-bottom: 100px;

        .table {
            background-color: #fff;
        }

        .pagination {
            display: flex;
            flex-direction: row;
            justify-content: right;
            margin-top: 10px;
        }
    }
}
</style>

<script setup>
import { ref, h } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NDataTable, NPagination, NButton, NTag, useMessage } from 'naive-ui';

import ControlBar from './components/ControlBar';
import CollectionEditor from './components/CollectionEditor';
import CollectionDataView from './components/CollectionDataView';
import CtrlCell from '@/components/CtrlCell';

import { CollectionAPI } from '@/common/API';
import Log from '@/common/log';
import { useRouteWatcher, useTags } from './components/tools';

const route = useRoute();
const router = useRouter();

const message = useMessage();

const tags = useTags(route);

let tableColumns = ref([
    {
        title: 'ID',
        key: 'id',
        width: 80,
        align: 'center',
    },
    {
        title: '名称',
        key: 'name',
        width: 280,
        ellipsis: true,
        resizable: true,
        render(row) {
            return (
                <NButton text onClick={() => setViewCollection(row)}>
                    {row.name}
                </NButton>
            );
        },
    },
    {
        title: '分类',
        key: 'category',
        width: 100,
        ellipsis: true,
        resizable: true,
        cellProps(row) {
            return { title: row.category };
        },
    },
    {
        title: '管理员',
        key: 'admin',
        width: 100,
        ellipsis: true,
        resizable: true,
    },
    {
        title: '标签',
        key: 'tags',
        resizable: true,
        render(row) {
            return row.tags.map(tag => (
                <NTag
                    size="small"
                    style={{ marginRight: '4px' }}
                    type="info"
                    round
                    onClick={() => appendTagToUri(tag)}
                >
                    {tag}
                </NTag>
            ));
        },
    },
    {
        title: '操作',
        width: 80,
        align: 'center',
        render(row) {
            return h(CtrlCell, {
                row,
                deleteConfirm: '确定删除该数据集?',
                onEdit: () => {
                    selectedCollection.value = row;
                    isEditorShow.value = true;
                },
                onDelete: () => {
                    deleteCollection(row.id);
                },
            });
        },
    },
]);

let tableData = ref([]);

let tableLoading = ref(false);

let pageIndex = ref(+route.query.index || 1);

let pageCount = ref(1);

let pageSize = ref(20);

let isEditorShow = ref(false);

function onCreate() {
    isEditorShow.value = true;
}

let selectedCollection = ref(null);

function deleteCollection(id) {
    message.loading('操作中...');
    CollectionAPI.deleteCollection(id).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        loadData();
    });
}

let viewCollection = ref(null);

let isViewCollectionShow = ref(false);

function setViewCollection(item) {
    viewCollection.value = item;
    isViewCollectionShow.value = true;
    Log.click('collection_view_show_data');
}

function editCollectionFromCollectionView() {
    selectedCollection.value = viewCollection.value;
    isEditorShow.value = true;
    isViewCollectionShow.value = false;
}

function appendTagToUri(tag) {
    let tags = route.query.tags ? route.query.tags.split(',') : [];
    if (tags.indexOf(tag) > -1) {
        return;
    }
    tags.push(tag);
    router.replace({
        query: { ...route.query, index: 1, tags: tags.join(',') },
    });
}

function loadData() {
    tableLoading.value = true;
    CollectionAPI.getCollectionList(
        pageIndex.value,
        pageSize.value,
        route.query.category,
        route.query.name,
        tags.value,
        route.query.admin
    ).then(res => {
        tableLoading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        tableData.value = res.data.data;
        pageCount.value = res.data.last_page;
    });
}

function onPageIndexChange(index) {
    router.replace({ query: { ...route.query, index } });
}

function onPageSizeChange(size) {
    pageSize.value = size;
    router.replace({
        query: { ...route.query, index: 1, t: new Date().getTime() },
    });
}

loadData();

useRouteWatcher(route)(() => {
    pageIndex.value = +route.query.index || 1;
    loadData();
});
</script>
