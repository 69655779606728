<template>
    <div>
        <n-drawer
            :show="props.show"
            width="70%"
            placement="right"
            @mask-click="closeDrawer"
        >
            <n-drawer-content class="collection-editor">
                <template #header>
                    <div class="drawer-header">
                        {{ originCollection ? '编辑' : '新建' }}数据集
                        <n-button
                            v-if="!!originCollection"
                            size="small"
                            style="position: absolute; top: -4px; right: 0px"
                            @click="onCopyCollection"
                            >复制数据集</n-button
                        >
                    </div>
                </template>
                <n-form
                    ref="collectionFormRef"
                    label-width="100px"
                    :rules="collectionFormRules"
                    :model="collectionForm"
                >
                    <n-form-item label="分类" path="category">
                        <n-select
                            v-model:value="collectionForm.category"
                            :options="categoryOpts"
                        ></n-select>
                        <n-button
                            style="margin-left: 10px"
                            @click="createCategory"
                            >新建分类</n-button
                        >
                    </n-form-item>
                    <n-form-item label="名称" path="name">
                        <n-input v-model:value="collectionForm.name"></n-input>
                    </n-form-item>
                    <n-form-item label="管理员" path="admin">
                        <n-input v-model:value="collectionForm.admin">
                            <template #prefix>
                                <n-popover
                                    trigger="hover"
                                    placement="top-start"
                                >
                                    <template #trigger>
                                        <n-icon><HelpFilled /></n-icon>
                                    </template>
                                    <span
                                        >使用英文逗号（,）分割，可以设置多位管理员</span
                                    >
                                </n-popover>
                            </template>
                        </n-input>
                    </n-form-item>
                    <n-form-item label="标签">
                        <n-dynamic-tags v-model:value="tags"></n-dynamic-tags>
                    </n-form-item>
                    <n-form-item label="Team&Group">
                        <team-group-picker v-model:value="teamGroups" />
                    </n-form-item>
                    <n-form-item label="条件筛选">
                        <div class="filter-view">
                            <template v-for="item in filterViewCtrl">
                                <div class="item">
                                    <div class="label">{{ item.label }}</div>
                                    <n-select
                                        size="small"
                                        class="select"
                                        v-model:value="filter[item.key]"
                                        :consistent-menu-width="false"
                                        :options="item.option"
                                        multiple
                                        filterable
                                        clearable
                                    ></n-select>
                                    <n-checkbox
                                        size="small"
                                        class="exclude"
                                        v-model:checked="excludeKeys[item.key]"
                                        >排除</n-checkbox
                                    >
                                </div>
                            </template>
                            <div class="item">
                                <div class="label">RI开关</div>
                                <n-radio-group
                                    v-model:value="filter.purchase"
                                    style="margin-left: 10px; margin-top: 2px"
                                    name="ri_purchase"
                                    size="small"
                                >
                                    <n-radio
                                        v-for="item in RIOptions"
                                        size="small"
                                        :value="item.value"
                                    >
                                        <span style="font-size: 12px">{{
                                            item.label
                                        }}</span>
                                    </n-radio>
                                </n-radio-group>
                            </div>
                            <div class="item">
                                <div class="label" style="width: 160px">
                                    SavingPlan未使用开关
                                </div>
                                <n-switch
                                    style="margin-top: 4px"
                                    v-model:value="filter.savingpu"
                                    size="small"
                                    :checked-value="1"
                                    :unchecked-value="0"
                                    :default-value="0"
                                />
                                <div style="width: 12vw"></div>
                            </div>
                        </div>
                    </n-form-item>
                    <n-form-item label="备注">
                        <n-input v-model:value="desc" type="textarea"></n-input>
                    </n-form-item>
                </n-form>
                <template #footer>
                    <span
                        style="
                            font-size: 13px;
                            color: #666;
                            margin-right: 4px;
                            margin-top: 4px;
                        "
                        >保存后打开预览</span
                    >
                    <n-switch
                        style="margin-right: auto; margin-top: 4px"
                        v-model:value="allowReview"
                    ></n-switch>
                    <n-button
                        :loading="submitLoading"
                        type="primary"
                        @click="submit"
                        >提交</n-button
                    >
                </template>
            </n-drawer-content>
        </n-drawer>
        <CollectionDataView
            v-model:show="showDataReview"
            :collection="reviewCollection"
            review
        ></CollectionDataView>
    </div>
</template>

<style lang="less" scoped>
.drawer-header {
    width: 67vw;
    position: relative;
}

.collection-editor {
    position: relative;

    .filter-view {
        width: 70vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            width: 49%;

            .label {
                font-size: 12px;
                margin-right: 4px;
                padding-top: 4px;
                width: 50px;
                text-align: right;
            }

            .select {
                width: 65%;
            }

            .exclude {
                width: 60px;
                font-size: 12px;
                margin-left: 4px;
                padding-top: 4px;
            }
        }
    }
}
</style>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from 'vue';

import {
    NButton,
    NForm,
    NFormItem,
    NInput,
    NSelect,
    NDrawer,
    NDrawerContent,
    NDynamicTags,
    NCheckbox,
    NRadioGroup,
    NRadio,
    NSwitch,
    NIcon,
    NPopover,
    useMessage,
    useDialog,
} from 'naive-ui';
import { HelpFilled } from '@vicons/material';

import { useUserStore } from '@/stores/global';

import TeamGroupPicker from '@/components/FinOpsTeamGroupPicker';

import { CollectionAPI, getFilterSuggestion } from '@/common/API';
import { isEmptyObject } from '@/common/tools';

import CollectionDataView from './CollectionDataView';

const message = useMessage();

const dialog = useDialog();

const userStore = useUserStore();

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    collection: {
        type: Object,
    },
});

watch(
    () => props.collection,
    col => {
        if (!col) {
            return;
        }
        // 有必要单独请求Collection Info，以防列表中的数据不是最新的，导致数据读脏
        loadCollection();
        collectionForm.value.category = col.category;
        collectionForm.value.name = col.name;
        collectionForm.value.admin = col.admin;
    }
);

function loadCollection() {
    message.loading('加载中...', { duration: 0 });
    CollectionAPI.getCollection(props.collection.id)
        .then(res => {
            message.destroyAll();
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            let col = res.data;
            collectionForm.value.category = col.category;
            collectionForm.value.name = col.name;
            collectionForm.value.admin = col.admin;
            desc.value = col.desc;
            tags.value = col.tags;
            teamGroups.value = col.teamgroups.map(
                item => item.team + ':' + item.group
            );
            if (isEmptyObject(col.feFilterState)) {
                return;
            }
            filter.value = col.feFilterState.filter;
            excludeKeys.value = col.feFilterState.excludeKeys;
        })
        .catch(err => {
            message.destroyAll();
            console.log(err);
            message.error('未知异常导致加载失败');
        });
}

const emits = defineEmits(['update:show', 'update:collection', 'complete']);

let originCollection = computed(() => {
    if (copyMode.value) {
        return null;
    }
    return (
        props.collection ||
        (!isEmptyObject(reviewCollection.value) ? reviewCollection.value : null)
    );
});

function closeDrawer() {
    emits('update:show', false);
    emits('update:collection', null);
    clearForm();
}

let collectionFormRef = ref();

let collectionFormRules = ref({
    category: [{ required: true, message: '分类不能为空', trigger: ['blur'] }],
    name: [{ required: true, message: '名称不能为空', trigger: ['blur'] }],
    admin: [{ required: true, message: '管理员不能为空', trigger: ['blur'] }],
});

let categoryOpts = ref([]);

function loadCategories() {
    CollectionAPI.getCategories().then(res => {
        if (res.error_no !== 0) {
            return;
        }
        categoryOpts.value = res.data.map(item => ({
            label: item,
            value: item,
        }));
    });
}

loadCategories();

function createCategory() {
    const owner = 'long.cai@mobvista.com';
    const subject = '申请新增Cost Lens的分类项';
    const content =
        '分类名：[此处修改为需要创建的分类名称（不多于20个字符）]\r\n用途：[此处简述一下分类的用途]\r\n如需加急，请在该Ticket提交后联系long.cai,kui.chen';

    dialog.info({
        title: '信息',
        content: '新建分类需要前往Ticket平台提交申请工单，确定前往么？',
        positiveText: '前往',
        negativeText: '取消',
        onPositiveClick() {
            window.open(
                `https://maxcloud.spotmaxtech.com/ticket?action=create&ticketType=normal&assignedTo=${owner}&subject=${encodeURIComponent(
                    subject
                )}&groupTag=cloudnative&content=${encodeURIComponent(content)}`
            );
        },
    });
}

let collectionForm = ref({
    category: '',
    name: '',
    admin: userStore.currentUser.username,
});

let desc = ref('');

let tags = ref([]);

let teamGroups = ref([]);

let filterViewCtrl = ref([
    {
        key: 'service',
        label: '服务',
        option: [],
    },
    {
        key: 'detail',
        label: '细分计费',
        option: [],
    },
    {
        key: 'region',
        label: '地域',
        option: [],
    },
    {
        key: 'cloud',
        label: '云商',
        option: [],
    },
]);

function loadFilterSuggestion() {
    getFilterSuggestion('service').then(res => {
        if (res.error_no !== 0) {
            return;
        }
        filterViewCtrl.value[0].option = res.data.map(item => ({
            label: item,
            value: item,
        }));
    });
    getFilterSuggestion('detail').then(res => {
        if (res.error_no !== 0) {
            return;
        }
        filterViewCtrl.value[1].option = res.data.map(item => ({
            label: item,
            value: item,
        }));
    });
    getFilterSuggestion('region').then(res => {
        if (res.error_no !== 0) {
            return;
        }
        filterViewCtrl.value[2].option = res.data.map(item => ({
            label: item,
            value: item,
        }));
    });
    getFilterSuggestion('cloud').then(res => {
        if (res.error_no !== 0) {
            return;
        }
        filterViewCtrl.value[3].option = res.data.map(item => ({
            label: item,
            value: item,
        }));
    });
}

loadFilterSuggestion();

let filter = ref({
    service: null,
    detail: null,
    region: null,
    cloud: null,
    purchase: 0,
    savingpu: 0,
});

let excludeKeys = ref({
    service: false,
    detail: false,
    region: false,
    cloud: false,
});

let RIOptions = ref([
    { label: '均摊RI', value: 0 },
    { label: '还原RI', value: 1 },
    { label: '排除RI', value: 2 },
]);

function clearForm() {
    collectionForm.value = {
        category: '',
        name: '',
        admin: userStore.currentUser.username,
    };
    desc.value = '';
    tags.value = [];
    teamGroups.value = [];
    filter.value = {
        service: null,
        detail: null,
        region: null,
        cloud: null,
        purchase: 0,
        savingpu: 0,
    };
    excludeKeys.value = {
        service: false,
        detail: false,
        region: false,
        cloud: false,
    };
    reviewCollection.value = {};
    copyMode.value = false;
}

let submitLoading = ref(false);

function submit() {
    collectionFormRef.value.validate(errors => {
        if (errors) {
            return;
        }
        let payload = {
            node: ['MV'],
            tree_type: '组织架构树',
            name: collectionForm.value.name,
            admin: collectionForm.value.admin,
            category: collectionForm.value.category,
            desc: desc.value,
            tags: tags.value,
            teamgroups: teamGroups.value.map(item => {
                let [team, group] = item.split(':');
                return { team, group };
            }),
            filters: {},
            // 前端专用
            feFilterState: {
                filter: filter.value,
                excludeKeys: excludeKeys.value,
            },
        };
        let include = {};
        let exclude = {};
        let correction = null;

        for (let key in excludeKeys.value) {
            let v = filter.value[key];
            if (!v) {
                continue;
            }
            (excludeKeys.value[key] ? exclude : include)[key] =
                typeof v === 'object' ? [...v] : [v];
        }
        // 还原RI
        if (filter.value.purchase === 1) {
            exclude.purchase = [];
            correction = [];
        }
        // 排除RI
        if (filter.value.purchase === 2) {
            exclude.purchase = ['ri'];
            correction = [];
        }
        // 特殊exclude.detail "savingplay_unused"的处理
        exclude.detail = isEmptyObject(exclude.detail) ? [] : exclude.detail;
        if (filter.value.savingpu !== 1) {
            exclude.detail.push('savingplan_unused');
        }
        payload.filters = {
            ...(Object.keys(include).length > 0 ? { include } : {}),
            ...(Object.keys(exclude).length > 0 ? { exclude } : {}),
            ...(correction ? { correction } : {}),
        };

        let promiseTask = originCollection.value
            ? CollectionAPI.updateCollection(originCollection.value.id, payload)
            : CollectionAPI.createCollection(payload);

        submitLoading.value = true;
        promiseTask
            .then(res => {
                submitLoading.value = false;
                if (res.error_no !== 0) {
                    message.error(res.error_msg[0] || '未知异常导致加载失败');
                    return;
                }
                message.success(
                    `${originCollection.value ? '编辑' : '创建'}成功，ID:${
                        res.data.id
                    }`
                );

                copyMode.value = false;

                if (allowReview.value) {
                    reviewCollection.value = {
                        id: res.data.id,
                        ...payload,
                    };
                    showDataReview.value = true;
                } else {
                    closeDrawer();
                }
                emits('complete');
            })
            .catch(err => {
                console.log(err);
                submitLoading.value = false;
                message.error('未知异常导致加载失败');
            });
    });
}

let allowReview = ref(true);

let showDataReview = ref(false);

let reviewCollection = ref({});

let copyMode = ref(false);

function onCopyCollection() {
    copyMode.value = true;
    message.success('已切换至复制数据集模式，提交后将创建一个新数据集');
}
</script>
