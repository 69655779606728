<template>
    <div class="finops-team-group-picker">
        <div class="select-view">
            <n-tabs size="tiny" type="segment">
                <n-tab-pane name="search" tab="检索添加">
                    <n-input
                        size="small"
                        v-model:value="keyword"
                        placeholder="输入Team&Group关键字，按Enter查询"
                        style="margin-bottom: 2px"
                        @keyup="onKeywordKeyup"
                    ></n-input>
                    <n-data-table
                        style="background-color: #fff; font-size: 12px"
                        size="tiny"
                        :columns="columns"
                        :data="resTableData"
                        :loading="genLoading"
                        :max-height="170"
                        virtual-scroll
                    ></n-data-table>
                </n-tab-pane>
                <n-tab-pane name="import" tab="快速导入">
                    <n-input
                        v-model:value="importTeamGroupsStr"
                        size="small"
                        type="textarea"
                        placeholder="输入team:group，可用英文,分隔以添加多个，请确认导入数据的正确性！"
                        :autosize="{ minRows: 9, maxRows: 9 }"
                    ></n-input>
                    <n-button
                        type="primary"
                        size="tiny"
                        style="margin-top: 4px"
                        @click="onImport"
                        >导入</n-button
                    >
                </n-tab-pane>
            </n-tabs>
        </div>
        <div class="selected-view">
            <div class="header">
                <div class="desc">
                    已选择{{ props.value.length }}个Team&Group
                </div>
                <n-button
                    size="tiny"
                    style="margin-left: auto"
                    quaternary
                    @click="exportTeamGroups"
                    >导出</n-button
                >
                <n-button
                    size="tiny"
                    quaternary
                    type="primary"
                    style="margin-left: 4px"
                    @click="clearPicked"
                    >清空</n-button
                >
            </div>
            <div class="content">
                <n-dynamic-tags
                    :value="props.value"
                    size="small"
                    round
                    @update:value="onTagUpdate"
                >
                    <template #trigger></template>
                </n-dynamic-tags>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../common/common.less';

.finops-team-group-picker {
    position: relative;
    width: 100%;
    height: 260px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 10px;
    .standard-border;

    .select-view {
        flex-grow: 1;
    }

    .selected-view {
        width: 320px;
        flex-shrink: 0;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        .header {
            font-size: 12px;
            color: #666;
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
            margin-bottom: 4px;
        }

        .content {
            height: 180px;
            background-color: #fff;
            border: 1px solid rgba(239, 239, 245, 1);
            flex-grow: 1;
            border-radius: 3px;
            overflow: auto;
            padding: 4px;
        }
    }
}
</style>

<script setup>
import { ref } from 'vue';

import {
    NInput,
    NDataTable,
    NButton,
    NDynamicTags,
    NTabs,
    NTabPane,
} from 'naive-ui';

import { useNodesStore } from '@/stores/business/Nodes';

const props = defineProps({
    value: {
        type: Array,
        default: () => [],
    },
    leafOnly: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits(['update:value']);

const nodeStore = useNodesStore();

const TREE_TYPE = '组织架构树';

let columns = ref([
    {
        key: 'key',
        title() {
            return (
                <NButton size="tiny" quaternary onClick={pickAll}>
                    全选
                </NButton>
            );
        },
        width: 40,
        render(row) {
            return (
                <NButton size="tiny" quaternary onClick={() => pick(row.label)}>
                    +
                </NButton>
            );
        },
        align: 'center',
    },

    {
        title: '模块',
        key: 'label',
        resizable: true,
        width: 200,
    },
    {
        title: '目前模块路径',
        key: 'key',
        ellipsis: true,
    },
]);

let resTableData = ref([]);

let teamGroupRes = [];

function loadData() {
    nodeStore.getArrayNodes(TREE_TYPE).then(data => {
        teamGroupRes = data.filter(item => item.leaf);
    });
}

let keyword = ref('');

function onKeywordKeyup(e) {
    if (e.code === 'Enter') {
        genTableData();
    }
}

let genLoading = ref(false);
function genTableData() {
    genLoading.value = true;
    setTimeout(() => {
        resTableData.value = teamGroupRes.filter(
            item =>
                item.key.toLowerCase().indexOf(keyword.value.toLowerCase()) > -1
        );
        genLoading.value = false;
    }, 100);
}

function pick(teamGroup) {
    if (props.value.indexOf(teamGroup) === -1) {
        emits('update:value', [...props.value, teamGroup]);
    }
}

function pickAll() {
    let addArr = [];
    resTableData.value.forEach(item => {
        if (props.value.indexOf(item.label) === -1) {
            addArr.push(item.label);
        }
    });
    emits('update:value', [...props.value, ...addArr]);
}

function clearPicked() {
    emits('update:value', []);
}

function onTagUpdate(value) {
    emits('update:value', value);
}

let importTeamGroupsStr = ref('');

function onImport() {
    if (importTeamGroupsStr.value === '') {
        return;
    }
    let importArr = importTeamGroupsStr.value.split(',');
    let addArr = [];
    importArr.forEach(item => {
        if (props.value.indexOf(item) === -1) {
            addArr.push(item);
        }
    });
    emits('update:value', [...props.value, ...addArr]);
    importTeamGroupsStr.value = '';
}

function exportTeamGroups() {
    let teamGroupStr = props.value.join(',');
    let blob = new Blob([teamGroupStr], { type: 'text/plain;charset=utf-8' });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'teamgroups.txt';
    link.click();
}

loadData();
</script>
