<template>
    <div class="ctrl-bar">
        <div class="label">分类：</div>
        <n-select
            v-model:value="category"
            size="small"
            :options="categoryOpts"
            style="width: 120px"
            class="input"
            :consistent-menu-width="false"
            clearable
        ></n-select>
        <div class="label">名称：</div>
        <n-input
            v-model:value="name"
            size="small"
            style="width: 200px"
            class="input"
            placeholder="前置匹配名称搜索"
            clearable
            @keyup="inputKeyup"
        ></n-input>
        <div class="label">标签：</div>
        <n-input
            v-model:value="tags"
            size="small"
            style="width: 200px"
            class="input"
            placeholder="多标签搜索（Tag1,Tag2,Tag3）"
            clearable
            @keyup="inputKeyup"
        ></n-input>
        <div class="label">管理员：</div>
        <n-input
            v-model:value="admin"
            size="small"
            style="width: 120px"
            class="input"
            clearable
            @keyup="inputKeyup"
        ></n-input>
        <n-button
            size="small"
            type="primary"
            style="margin-bottom: 4px"
            round
            @click="onQuery"
        >
            <template #icon>
                <n-icon>
                    <SearchFilled />
                </n-icon>
            </template>
            查询
        </n-button>
        <n-button
            class="submit"
            size="small"
            type="primary"
            @click="onCreate"
            ghost
        >
            新建数据集
        </n-button>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.ctrl-bar {
    .common-ctrl-bar;
}
</style>

<script setup>
import { ref, defineEmits, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NButton, NInput, NSelect, NIcon } from 'naive-ui';
import { SearchFilled } from '@vicons/material';

import { CollectionAPI } from '@/common/API';

const emits = defineEmits(['createClick']);

const route = useRoute();

const router = useRouter();

let categoryOpts = ref([]);

let category = ref('');

let name = ref('');

let tags = ref('');

let admin = ref('');

function onCreate() {
    emits('createClick');
}

function loadCategories() {
    CollectionAPI.getCategories().then(res => {
        if (res.error_no !== 0) {
            return;
        }
        categoryOpts.value = res.data.map(item => ({
            label: item,
            value: item,
        }));
    });
}

loadCategories();

function onQuery() {
    router.replace({
        query: {
            ...(category.value ? { category: category.value } : {}),
            ...(tags.value ? { tags: tags.value } : {}),
            ...(admin.value ? { admin: admin.value } : {}),
            ...(name.value ? { name: name.value } : {}),
        },
    });
}

function inputKeyup(e) {
    if (e.key === 'Enter') {
        onQuery();
    }
}

onMounted(() => {
    category.value = route.query.category || '';
    tags.value = route.query.tags || '';
    admin.value = route.query.admin || '';
    name.value = route.query.name || '';
});

watch(
    () => route.query.tags,
    value => {
        tags.value = value;
    }
);
</script>
